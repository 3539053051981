import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

// action types

const LANG = "LANG"

const AMT = "AMT"

const PD = 'PD'

// action creators

export function changeLANG(val) {
    return { type: LANG, val }
}

export function changeAMT(val) {
    return { type: AMT, val }
}

export function changePD(val) {
    return { type: PD, val }
}

// reducer

const reducer = (state, action) => {
    switch (action.type) {
        case LANG:
            return {
                ...state,
                lang: action.val
            }
        case AMT:
            return {
                ...state,
                amt: action.val
            }
        case PD:
            return {
                ...state,
                paid: action.val
            }
        default:
            return state;
    }
}

// initial state

const initialState = {
    lang: 'none',
    amt: 'none',
    paid: 'no'
}

// store

const createStore = () =>
    reduxCreateStore(reducer, initialState, composeWithDevTools(applyMiddleware()));
export default createStore;