// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-js": () => import("./../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-story-js": () => import("./../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-wedding-js": () => import("./../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */)
}

